import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
@Component({
  name: 'GtrRegistrationModuleEditOptionView',
  computed: {
    ...mapState('option', ['option_groups']),
    ...mapState('pricing', ['pricing_tiers']),
    ...mapState('event', ['eventAllContent'])
  }
})
export default class GtrRegistrationModuleEditOptionView extends Vue {
  data () {
    return {
      loading: false,
      submitting: false,
      event_uuid: this.$route.params.event_uuid,
      optionsYesNo: [
        {
          text: 'Yes',
          value: true
        },
        {
          text: 'No',
          value: false
        }
      ],
      option_group_uuid: this.$route.params.option_group_uuid,
      option_uuid: this.$route.params.option_uuid,
      option: {
        languageToUse: 'en',
        details: {},
        popup_details: {
          title: '',
          body: ''
        }
      },
      option_group: {},
      allContent: null,
      default_option_groups: ['Additional User Line Items'],
      pricing_for_option: [],
      pricingTiers: [],
      pricingTiersForSelect: [],
      registration_types: [],
      display: 1,
      display_order: 0
    }
  }

  // #region Watchers
  @Watch('eventAllContent')
  onEventAllContentChange (payload: any) {
    this.$data.allContent = payload
  }

  @Watch('option_groups')
  onOptionGroupsChange (payload: any) {
    if (Array.isArray(payload)) {
      /**
       * Isolate Registration Types option group
       */
      this.$data.registration_types = payload.filter(group => group.name === 'Registration Types')
      this.$data.registration_types = this.$data.registration_types.shift().options
      const registration_types_names = [
        {
          text: 'Default',
          value: '_default'
        }
      ]
      this.$data.option_group = payload.find((group: any) => group.uuid === this.$route.params.option_group_uuid)
      if (this.$data.option_group.name !== 'Registration Types') {
        for (let i = 0; i < this.$data.registration_types.length; i++) {
          registration_types_names.push({
            text: this.$data.registration_types[i].name,
            value: this.$data.registration_types[i].uuid
          })
        }
      }
      this.$data.registration_types = registration_types_names
      if (!this.$data.option_uuid) {
        return
      }
      this.$data.option = this.$data.option_group.options.filter((option: any) => option.uuid === this.$route.params.option_uuid)
      this.$data.option = this.$data.option.shift()
      if (!this.$data.option?.popup_details) {
        this.$data.option.popup_details = {
          title: '',
          body: ''
        }
      }
      this.$data.option_uuid = this.$data.option.uuid
      this.$data.pricing_for_option = this.$data.option.pricing ?? []
      this.$data.pricing_for_option.map(pricing_option => {
        this.$data.pricingTiers.map(price_tier => {
          if (!pricing_option.tiered_pricing[price_tier.uuid]) {
            pricing_option.tiered_pricing[price_tier.uuid] = {
              price: '0',
              cancel_price: '0'
            }
          }
        })
      })
    }
  }

  @Watch('pricing_tiers')
  onPricingTiersChange (payload: any) {
    this.$data.pricingTiers = payload
    payload.forEach((tier: any) => {
      this.$data.pricingTiersForSelect.push({
        text: tier.name,
        value: tier.uuid
      })
    })
  }

  @Watch('languageItems')
  onLanguageItemsChange (payload: any) {
    let counter = 0
    payload.forEach(() => {
      /**
       * Make the first language in the object the default
       */
      if (counter === 0) {
        // this.$data.option.languageToUse = language.value
        this.$data.option.languageToUse = 'en'
      }
      counter++
    })
  }
  // #endregion

  // #region Computed properties
  get isThisADefaultOptionGroup () {
    if (this.$data.option_group) {
      return this.$data.default_option_groups.includes(this.$data.option_group.name)
    }
  }

  get languageItems () {
    const arr: any [] = []
    if (this.$data.allContent) {
      for (const language_key in this.$data.allContent.languages) {
        arr.push({
          text: this.$data.allContent.languages[language_key],
          value: language_key
        })
      }
    }
    return arr
  }
  // #endregion

  // #region Methods
  async mounted () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('pricing/getPricingTiers', { event_uuid: this.$route.params.event_uuid })
      await this.$store.dispatch('option/getOptionsGroup', { event_uuid: this.$route.params.event_uuid })
      await this.$store.dispatch('event/getEventAllContent', this.$route.params.event_uuid)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private handleRegtypeSpecificChange (value: boolean) {
    if (!value) {
      this.$data.option.reg_types = []
    }
  }

  async submit () {
    const form = (this.$refs.editOptionForm as HTMLFormElement)
    if (form.validate()) {
      try {
        this.$data.submitting = true
        const payload = {
          event_uuid: this.$data.event_uuid,
          option_group_uuid: this.$data.option_group_uuid,
          option_uuid: this.$data.option_uuid,
          data: {
            name: this.$data.option.name,
            details: this.$data.option.details,
            taxed: this.$data.option.taxed,
            cap: this.$data.option.cap,
            display: this.$data.option.display,
            display_order: this.$data.option.display_order,
            visible: this.$data.option.visible,
            pricing: this.$data.pricing_for_option,
            popup_details: this.$data.option.popup_details,
            regtype_specific: this.$data.option.regtype_specific,
            reg_types: this.$data.option.reg_types
          }
        }
        await this.$store.dispatch('option/editOption', payload)
        Container.get(Notification).success('Option successfully edited.')
        this.$router.push({
          name: 'level-two.modules.registration.options.edit',
          params: {
            option_uuid: this.$data.option_group_uuid
          }
        })
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.submitting = false
      }
    }
  }

  deletePricingItem (pricing_item_uuid: string) {
    this.$data.pricing_for_option = this.$data.pricing_for_option.filter(
      (pricing_item: any) => pricing_item.uuid !== pricing_item_uuid
    )
  }

  addPricingForOption () {
    const pricing_item = {
      uuid: Vue.prototype.$uuid.v4(),
      reg_type: '',
      tiered_pricing: {}
    }
    /**
     * Add each pricing tier's uuid to the pricing item so that it exists when we try to access it with v-model
     */
    this.$data.pricingTiers.forEach((tier: any) => {
      pricing_item.tiered_pricing[tier.uuid] = {
        price: '0',
        cancel_price: '0'
      }
    })
    this.$data.pricing_for_option.push(pricing_item)
  }
  // #endregion
}
